import { useGetProviderSearchPatients } from "apollo/hooks/queries";
import {
  PROVIDER_SEARCH_BAVARIA,
  PROVIDER_SEARCH_VERSION_BAVARIA,
  PROVIDER_SEARCH_VERSION_MAP,
  REDIRECT_REASON_PARAM,
} from "core/consts";
import { useSafeState } from "core/hooks";
import { TOKEN_STATUS } from "core/model/accounts";
import {
  PROVIDER_SEARCH_CONFIG,
  ProviderSearchConfig,
} from "core/model/providerSearch/config";
import { ActiveProviderSearch, ProviderSearchPatients } from "core/types";
import {
  PROVIDER_SEARCH_LOGIN_MODAL,
  ProviderSearchLoginModalType,
} from "dsl/ecosystems/ProviderSearchLoginModal/shared";
import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useOnLogout } from "reduxentities/actions";
import {
  selectLoggedCareseeker,
  useIsLoggedOnProviderSearchApp,
  useLoggedInAccount,
} from "reduxentities/selectors";
import { useLoggedCareseeker } from "reduxentities/selectors/hooks";

export type ProviderSearchContext = {
  config: ProviderSearchConfig;
  isLoggedIn: boolean;
  loginModal: ProviderSearchLoginModalType;
  patients: ProviderSearchPatients;
  providerSearch: ActiveProviderSearch;
  setLoginModal: React.Dispatch<
    React.SetStateAction<ProviderSearchLoginModalType>
  >;
};

const defaultProviderSearch = PROVIDER_SEARCH_BAVARIA;

const defaultProviderSearchContext: ProviderSearchContext = {
  config: PROVIDER_SEARCH_CONFIG[defaultProviderSearch],
  providerSearch: defaultProviderSearch,
  loginModal: null,
  setLoginModal: () => {},
  isLoggedIn: false,
  patients: { active: [], archived: [] },
};

const ProviderSearchContext = createContext<ProviderSearchContext>(
  defaultProviderSearchContext,
);

export function useProviderSearchContext() {
  return useContext<ProviderSearchContext>(ProviderSearchContext);
}

const useLoggedInProviderSearch = (): ProviderSearchContext["isLoggedIn"] => {
  const dispatch = useDispatch();
  const logged = useIsLoggedOnProviderSearchApp();
  const onLogout = useOnLogout();
  const account = useLoggedInAccount();

  useEffect(() => {
    if (logged !== "ok") {
      if (account?.id) {
        // temporary log to confirm why users are getting logged out
        if (logged !== "noToken") {
          console.error("provider search user logged out", { logged });
        }
        onLogout({ tokenExpired: logged === TOKEN_STATUS.EXPIRED });
      }
    }
  }, [logged, dispatch]);

  return logged === "ok";
};

const useGetProviderSearchConfig = (): Pick<
  ProviderSearchContext,
  "config" | "providerSearch"
> => {
  const { provider_search } = useParams();
  const [providerSearch, setProviderSearch] =
    useSafeState<ActiveProviderSearch>(PROVIDER_SEARCH_BAVARIA);
  const navigate = useNavigate();

  useEffect(() => {
    // if no state param could be in the login/logout routes
    if (!provider_search) return;

    const providerSearchFromParams =
      PROVIDER_SEARCH_VERSION_MAP?.[
        provider_search as keyof typeof PROVIDER_SEARCH_VERSION_MAP
      ];

    // if no provider search found from params - it is incorrectly entered into the URL; redirect user to listing
    if (providerSearchFromParams == null) {
      // derive from production domains when multiple providersearch's released
      const fallback = PROVIDER_SEARCH_VERSION_BAVARIA;

      console.error(
        `could not determine provider search from param "${provider_search}" redirecting to fallback listing ${fallback}`,
      );

      navigate(`providersearch/${fallback}/listing`);
      return;
    }

    // if there is a correct param entered and it is not equal to that saved, user changed state
    if (providerSearchFromParams !== providerSearch) {
      console.info(
        `resetting provider search from "${providerSearch}" to "${providerSearchFromParams}"`,
      );
      setProviderSearch(providerSearchFromParams);
    }
  }, [provider_search]);

  return {
    providerSearch,
    config: PROVIDER_SEARCH_CONFIG[providerSearch],
  };
};

const useGetProviderSearchContext = (): ProviderSearchContext => {
  const { config, providerSearch } = useGetProviderSearchConfig();
  const [search] = useSearchParams();
  const isLoggedIn = useLoggedInProviderSearch();
  const loggedCareseeker = useSelector(selectLoggedCareseeker);
  const careseeker = useLoggedCareseeker({
    fetchPolicy: isLoggedIn ? "cache-first" : undefined,
    skip: !loggedCareseeker?.id,
  });
  const [, patients = { active: [], archived: [] }] =
    useGetProviderSearchPatients({
      skip: !careseeker?.id,
    });
  const [loginModal, setLoginModal] =
    useState<ProviderSearchLoginModalType>(null);

  useEffect(() => {
    if (search.has(REDIRECT_REASON_PARAM)) {
      setLoginModal(PROVIDER_SEARCH_LOGIN_MODAL);
    }
  }, [search]);

  return {
    loginModal,
    setLoginModal,
    providerSearch,
    config,
    isLoggedIn,
    patients: {
      active: patients.active ?? [],
      archived: patients.archived ?? [],
    },
  };
};

export const ProviderSearchProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const context = useGetProviderSearchContext();

  if (!context.providerSearch || !context.config) return null;

  return (
    <ProviderSearchContext.Provider value={context}>
      {children}
    </ProviderSearchContext.Provider>
  );
};

export const TestProviderSearchProvider = ({
  children,
  providerSearchContext,
}: {
  children: React.ReactNode;
  providerSearchContext?: Partial<ProviderSearchContext>;
}) => {
  return (
    <ProviderSearchContext.Provider
      value={{ ...defaultProviderSearchContext, ...providerSearchContext }}
    >
      {children}
    </ProviderSearchContext.Provider>
  );
};
