import { visuallyHidden } from "@mui/utils";
import { WHITE } from "ds_legacy/materials/colors";
import { Z_INDEX_MAX, dp, margin, padding } from "ds_legacy/materials/metrics";
import { BCP_BASE_DARK } from "ds_legacy/materials/palettes/bcp";
import {
  FONT_FAMILY,
  FONT_SIZE_14,
  FONT_WEIGHT_SEMI_BOLD,
} from "ds_legacy/materials/typography";
import { focusOutlineStyles } from "ds_legacy/styles";
import { useFocusElement } from "dsl/hooks/useFocusElement";
import { MouseEvent } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useTranslations } from "translations";

export const CONTENT_ID = "maincontent" as const;

const StyledSkipLink = styled("a")`
  height: ${dp(1)};
  left: ${dp(-9999)};
  overflow: hidden;
  position: fixed;
  top: auto;
  width: ${dp(1)};
  font-family: ${FONT_FAMILY};

  &:focus-visible {
    background-color: ${WHITE};
    border-radius: 8px;
    color: ${BCP_BASE_DARK};
    font-size: ${FONT_SIZE_14};
    font-weight: ${FONT_WEIGHT_SEMI_BOLD};
    height: auto;
    justify-content: center;
    left: 50%;
    padding: ${padding(0.25, 1.5)};
    margin: ${margin(0.5, 1.5)};
    text-align: center;
    top: ${dp(4)};
    transform: translateX(-50%);
    white-space: nowrap;
    width: auto;
    z-index: ${Z_INDEX_MAX};
  }
  ${focusOutlineStyles()};
`;

export default function SkipLink() {
  const translations = useTranslations();
  const location = useLocation();
  const firstFocusableElement = useFocusElement<HTMLSpanElement>({
    delay: 300,
    dependencies: [location.pathname],
    setTabIndex: true,
  });

  function handleSkip(event: MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    const contentElement = document.getElementById(CONTENT_ID);
    if (contentElement) {
      contentElement.tabIndex = -1;
      contentElement.focus();
    }
  }

  return (
    <>
      {/* This span is used to make the first tabbulation land on the logo on all pages */}
      <span
        id="first-focusable-element"
        ref={firstFocusableElement}
        style={visuallyHidden}
        aria-hidden
      />
      <StyledSkipLink
        data-testid="skip-link"
        onClick={handleSkip}
        href={`#${CONTENT_ID}`}
      >
        {translations.accessibility.skipLink}
      </StyledSkipLink>
    </>
  );
}
