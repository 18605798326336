import "webcrypto-shim";
// @ts-ignore
import CustomCryptoWorker from "worker-loader!core/model/crypto/crypto.worker";

export let cryptoService: Crypto;
export let CryptoWorker: ServiceWorker;

export type CryptoServices = {
  service: Crypto;
  worker: ServiceWorker;
};

export function configureCrypto(): CryptoServices | undefined {
  return {
    service: window.crypto,
    worker: CustomCryptoWorker as unknown as ServiceWorker,
  };
}

export const setCryptoConfig = (
  newCryptoConfig: CryptoServices | undefined,
): void => {
  if (!newCryptoConfig) return;

  cryptoService = newCryptoConfig.service;
  CryptoWorker = newCryptoConfig.worker;
};
