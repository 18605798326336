import { QUERY_PROGRESS_PENDING } from "core/consts";
import { QueryProgress } from "core/types";
import { useEffect, useRef } from "react";

// add warning to page reloads triggered by the user e.g. during login when in progress
export default function usePreventUnload(
  progress: QueryProgress,
  skip?: boolean,
) {
  const loginPendingRef = useRef(false);
  useEffect(() => {
    if (progress === QUERY_PROGRESS_PENDING && !skip) {
      loginPendingRef.current = true;
    } else {
      loginPendingRef.current = false;
    }
  }, [progress]);

  useEffect(() => {
    const beforeUnloadListener = (event: BeforeUnloadEvent) => {
      if (loginPendingRef.current) {
        if (window.forceReload) {
          window.forceReload = false;
        } else {
          event.preventDefault();
          return (event.returnValue = "Are you sure?");
        }
      }
    };

    window.addEventListener("beforeunload", beforeUnloadListener);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadListener);
    };
  }, []);
}
