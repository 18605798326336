import { Breakpoint } from "@mui/material";
import { getResponsiveCSSMediaQueries } from "ds_legacy/materials/metrics";
import { ReactNode } from "react";
import { useMediaQuery } from "react-responsive";

type MediaBreakpoints = {
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
};
// note that isTablet is true for mobile and tablet breakpoints
export const useMedia = (): MediaBreakpoints => {
  const { desktop, mobile, tablet } = getResponsiveCSSMediaQueries();
  return {
    isMobile: useMediaQuery({
      query: mobile,
    }),
    isTablet: useMediaQuery({
      query: tablet,
    }),
    isDesktop: useMediaQuery({
      query: desktop,
    }),
  };
};

type PickedBreakpoints = Extract<Breakpoint, "desktop" | "mobile" | "tablet">;

type ResponsiveRendererProps =
  | {
      children: ReactNode;
      hide: PickedBreakpoints[];
      show?: never;
    }
  | {
      children: ReactNode;
      hide?: never;
      show: PickedBreakpoints[];
    };

export const shouldShowComponent = ({
  hide,
  isDesktop,
  isMobile,
  isTablet,
  show,
}: Omit<ResponsiveRendererProps, "children"> & MediaBreakpoints): boolean => {
  const isHiding = !!hide?.length;
  const breakpoints = hide?.length ? hide : show;

  if (!breakpoints) {
    return true;
  }

  if (isDesktop && breakpoints.includes("desktop")) {
    return !isHiding;
  }
  if (isMobile && breakpoints.includes("mobile")) {
    return !isHiding;
  }
  if (isTablet && !isMobile && breakpoints.includes("tablet")) {
    return !isHiding;
  }

  return isHiding;
};

/**
 * ResponsiveRenderer component for conditional rendering based on breakpoints.
 *
 * This component allows you to conditionally render its children based on the
 * detected device breakpoints using the `useMedia` hook.
 *
 * Note that tablet is for tablet only, and not for mobile breakpoints also
 *
 * @param children - The ReactNode to be rendered conditionally.
 * @param hide - An array of breakpoints where the children should be hidden.
 * @param show - An array of breakpoints where the children should be shown.
 *
 * @example
 * // Render the children only on mobile devices.
 * <ResponsiveRenderer show={['mobile']}>Mobile Content</ResponsiveRenderer>
 *
 * @example
 * // Hide the children on desktop and tablet devices.
 * <ResponsiveRenderer hide={['desktop', 'tablet']}>Hide on Desktop and Tablet</ResponsiveRenderer>
 *
 * @returns The rendered ReactNode based on the responsive conditions.
 */
export const ResponsiveRenderer = ({
  children,
  hide,
  show,
}: ResponsiveRendererProps) => {
  const { isDesktop, isMobile, isTablet } = useMedia();

  if (shouldShowComponent({ hide, show, isDesktop, isMobile, isTablet })) {
    return <>{children}</>;
  }

  return null;
};
