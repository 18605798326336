import {
  DialogActions,
  DialogContentText,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { useProviderSearchContext } from "context/ProviderSearchContext";
import { DoneIcon } from "ds/icons";
import RSButton from "ds_legacy/components/RSButton";
import { dp, margin } from "ds_legacy/materials/metrics";
import { Subheading } from "ds_legacy/materials/typography";
import { Halo } from "dsl/atoms/Halo";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { ReactNode } from "react";
import styled from "styled-components";
import { useTranslations } from "translations";
import { TranslationComposition } from "translations/helpers";
import { getDialogProps } from ".";
import { DialogContentWrapper, ProviderSearchDialog } from "./shared";

const StyledDialogContentText = styled(DialogContentText)`
  > p {
    text-align: center;
  }
`;

export const SuccessModal = ({
  content,
  testId,
  title,
}: {
  content: ReactNode;
  testId: string;
  title: string;
}) => {
  const { setLoginModal } = useProviderSearchContext();
  const translations = useTranslations();
  const theme = useTheme();

  return (
    <DialogContentWrapper testId={testId}>
      <Halo backgroundColor={theme.palette.success.light}>
        <DoneIcon
          style={{ fontSize: dp(72), color: theme.palette.success.dark }}
          size={dp(72)}
        />
      </Halo>
      <DialogTitle align="center" textAlign="center">
        {title}
      </DialogTitle>
      <StyledDialogContentText as="div">{content}</StyledDialogContentText>
      <DialogActions>
        <RSButton
          color="primary"
          id="create-success"
          loading="na"
          onClick={() => setLoginModal(null)}
          variant="contained"
        >
          {translations.providersearch.loginModal.loginModal.successCTA}
        </RSButton>
      </DialogActions>
    </DialogContentWrapper>
  );
};

export const LoginSuccess = ({ onClose }: { onClose: () => void }) => {
  const { loginModal } = useProviderSearchContext();
  const translations = useTranslations();
  const { isMobile } = useMedia();
  const props = getDialogProps({ loginModal, isMobile });
  return (
    <ProviderSearchDialog
      ariaLive="polite"
      onClose={onClose}
      paperStyle={props.paperStyle}
    >
      <SuccessModal
        testId="login-success"
        title={translations.providersearch.loginModal.loginModal.successTitle}
        content={
          <Subheading
            as="p"
            margin={margin(0)}
            textAlign="center"
            whiteSpace="normal"
          >
            {translations.providersearch.loginModal.loginModal.successText}
          </Subheading>
        }
      />
    </ProviderSearchDialog>
  );
};

export const SignupSuccess = ({
  accountEmail,
  onClose,
}: {
  accountEmail: string | undefined;
  onClose: () => void;
}) => {
  const translations = useTranslations();
  const { loginModal } = useProviderSearchContext();
  const { isMobile } = useMedia();
  const props = getDialogProps({ loginModal, isMobile });

  return (
    <ProviderSearchDialog
      ariaLive="polite"
      onClose={onClose}
      paperStyle={props.paperStyle}
    >
      <SuccessModal
        testId="signup-success"
        title={
          translations.providersearch.loginModal.createAccount.successTitle
        }
        content={
          <>
            <Subheading
              as="p"
              margin={margin(0)}
              textAlign="center"
              whiteSpace="normal"
            >
              <TranslationComposition
                translations={
                  translations.providersearch.loginModal.createAccount
                    .successText
                }
                withOptions
              >
                {([before, _, after]) => (
                  <>
                    {before}
                    <br />
                    <span
                      style={{ fontWeight: "bold", wordBreak: "break-word" }}
                    >
                      {accountEmail}
                    </span>
                    <br />
                    {after}
                    <br />
                    <br />
                    {
                      translations.providersearch.loginModal.createAccount
                        .successCaption
                    }
                  </>
                )}
              </TranslationComposition>
            </Subheading>
          </>
        }
      />
    </ProviderSearchDialog>
  );
};
